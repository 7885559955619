import dayjs from "dayjs";
import Link from "next/link";
import { FC } from "react";

import BlogListingBaseCard from "@/design-system/BlogListingCard/base";
import Box from "@/design-system/Box";
import Container from "@/design-system/Container";
import GridContainer from "@/design-system/GridContainer";
import EditorPickSlider from "@/design-system/Slider/EditorPickSlider/EditorPickSlider";
import Typography from "@/design-system/Typography";

import { theme } from "@/theme";

const EditorsPick: FC<{ page: string; blogsData: any[] }> = ({ page, blogsData }) => {
  return (
    <Box display={{ _: page === "home" ? "none" : "block", md: "unset" }}>
      <Container>
        <GridContainer
          gridAutoRows={page === "learninghub" ? "1fr" : "unset"}
          display={page === "home" ? "none" : "block"}
          mb={{
            _: page === "learninghub" ? "72px" : "48px",
            md: page === "learninghub" ? "120px" : "32px",
          }}
        >
          <Box
            mt={{ _: "16px", md: "32px" }}
            gridColumn={{ _: "span 12", md: "span 8" }}
            borderRadius={"8px"}
            overflow={"hidden"}
            width={"100%"}
          >
            <EditorPickSlider data={blogsData.slice(0, 3)} />
          </Box>
          {blogsData.length > 3 ? (
            <Box
              mt={{ _: "32px", md: "32px" }}
              gridColumn={{ _: "span 12", md: "span 4" }}
              borderRadius={"8px"}
              p={{ md: "0px 32px 0px 32px" }}
              width={"100%"}
              display={{ _: page === "home" ? "none" : "block", md: "unset" }}
            >
              <Box>
                <Typography
                  color={theme.colors.gray[900]}
                  varient="heading6"
                  weightVarient="semibold"
                >
                  Editor's Picks
                </Typography>
              </Box>
              {blogsData &&
                blogsData.slice(3, 6).map((blog: any, i: number) => {
                  return (
                    <Link
                      href={`/blog/${blog.url}`}
                      key={blog.name}
                      target={blog?.isHubspot ? "_blank" : "_self"}
                    >
                      <BlogListingBaseCard
                        badgeText={blog.categories__name}
                        para={blog.name}
                        authorImage={{
                          alt: blog.author__img,
                          src: `${process.env.NEXT_PUBLIC_CDN}/images/blog/authors/${blog?.author__img}`,
                        }}
                        authorName={`${blog.author__firstName}` + " " + `${blog.author__lastName}`}
                        date={dayjs(blog.createdAt).format(`DD MMM'YY`)}
                        readingTime={blog.readtime}
                        my={theme.spacing(4)}
                      />
                      {i < blogsData.length - 1 ? (
                        <Box
                          display={"inline-block"}
                          height={"0px"}
                          width={"100%"}
                          border={`1px solid ${theme.colors.gray[300]}`}
                        />
                      ) : null}
                    </Link>
                  );
                })}
            </Box>
          ) : null}
        </GridContainer>
      </Container>
    </Box>
  );
};
export default EditorsPick;
