import ExportedImage from "next-image-export-optimizer";

import { theme } from "@/theme";

import Badge from "../Badge/badge";
import Box from "../Box";
import FlexContainer from "../FlexContainer";
import Typography from "../Typography";

const BlogListingBaseCard = ({
  badgeText,
  para,
  authorImage,
  authorName,
  date,
  readingTime,
  ...rest
}: any) => {
  return (
    <Box {...rest}>
      <Box mb="16px">
        <Badge size="xs" colorVarient="gray" badgeStyle="light" text={badgeText} />
      </Box>
      <Box mb={"12px"}>
        <Typography color={theme.colors.gray[900]} varient="bodyl" weightVarient="medium">
          {para}
        </Typography>
      </Box>
      <FlexContainer width={"100%"}>
        <Box display="inline-block">
          <Box
            display={"inline-block"}
            borderRadius={"100%"}
            overflow={"hidden"}
            width={"16px"}
            height={"16px"}
            mr={"8px"}
            top={"3px"}
          >
            <ExportedImage unoptimized alt={authorImage.alt} fill src={authorImage.src} />
          </Box>
          <Typography color={theme.colors.gray[800]} varient="captionl" weightVarient="medium">
            {authorName}
          </Typography>
        </Box>
        <Box marginLeft={"auto"} mr="0px" display="inline-block">
          <Typography
            color={theme.colors.gray[600]}
            varient="captionl"
            weightVarient="medium"
            mr={"8px"}
          >
            {date}
          </Typography>
          <Box
            display={"inline-block"}
            height={"8px"}
            width={"0px"}
            border={`1px solid ${theme.colors.gray[400]}`}
            mr={"8px"}
          />
          <Typography color={theme.colors.gray[600]} varient="captionl" weightVarient="medium">
            {readingTime}
          </Typography>
        </Box>
      </FlexContainer>
    </Box>
  );
};

export default BlogListingBaseCard;
